/* eslint-disable max-lines */
import styled from "styled-components";
import { Button } from "../../ui";
import geocoderArrow from "../../assets/images/geocoder-arrow.svg";
import geocoderArrowMobile from "../../assets/images/geocoder-arrow-mobile.svg";
import lensArrow from "../../assets/images/lens-arrow.svg";
import lensArrowMobile from "../../assets/images/lens-arrow-mobile.svg";
import lens from "../../assets/images/lens.png";
import modeArrow from "../../assets/images/mode-arrow.svg";
import modeArrowMobile from "../../assets/images/mode-arrow-mobile.svg";
export const ZeroScreenContainer = styled.div `
  position: absolute;
  width: 100vw;
  height: var(--app-height);
  background: rgba(229, 229, 229, 0.5);
  backdrop-filter: blur(2px);
  z-index: 10;
  cursor: default;

  &:before {
    content: "";
    position: absolute;
    width: 100vw;
    height: var(--app-height);
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(87, 50, 211, 0.3));
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  @media (hover: hover) {
    &:hover {
      &:before {
        opacity: 1;
        transition: opacity 0.3s ease-in;
      }
    }
  }
`;
export const ZeroScreenGeocoderPlaceholder = styled.div `
  position: absolute;
  top: 30px;
  left: 30px;
  width: 400px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 0 11px rgb(0 0 0 / 6%);
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.011em;
  color: #7b7997;
  cursor: default;
  z-index: 11;

  @media only screen and (max-width: 768px) {
    & {
      top: 8px;
      left: 8px;
      width: calc(100% - 16px);
      box-sizing: border-box;
      height: 52px;
      font-size: 12px;
      padding: 17px 20px;
    }
  }
`;
export const GeocoderArrow = styled.div `
  position: absolute;
  top: 145px;
  left: 165px;
  width: 135px;
  height: 129px;
  background-image: url(${geocoderArrow});
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 12;

  @media only screen and (max-width: 768px) {
    & {
      top: 75px;
      left: 30px;
      width: 16px;
      height: 77px;
      background-image: url(${geocoderArrowMobile});
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 820px) {
    & {
      top: 115px;
    }
  }

  @media only screen and (min-width: 821px) and (max-width: 1180px) {
    & {
      top: 115px;
    }
  }
`;
export const GeocoderLabel = styled.div `
  position: absolute;
  top: 250px;
  left: 320px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #5732d3;
  max-width: 280px;
  z-index: 12;

  @media only screen and (max-width: 768px) {
    & {
      top: 160px;
      left: 43px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      max-width: 158px;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 820px) {
    & {
      top: 220px;
    }
  }

  @media only screen and (min-width: 821px) and (max-width: 1180px) {
    & {
      top: 220px;
    }
  }
`;
export const LensArrow = styled.div `
  position: absolute;
  bottom: 80px;
  right: 580px;
  width: 232px;
  height: 55px;
  background-image: url(${lensArrow});
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 12;

  @media only screen and (max-width: 768px) {
    & {
      bottom: 126px;
      right: 210px;
      width: 92px;
      height: 83px;
      background-image: url(${lensArrowMobile});
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 820px) {
    & {
      bottom: 60px;
      right: 350px;
    }
  }

  @media only screen and (min-width: 821px) and (max-width: 1180px) {
    & {
      bottom: 60px;
      right: 350px;
    }
  }
`;
export const LensLabel = styled.div `
  position: absolute;
  bottom: 155px;
  right: 813px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #5732d3;
  max-width: 180px;
  text-align: right;
  z-index: 12;

  @media only screen and (max-width: 768px) {
    & {
      bottom: 220px;
      right: 222px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      max-width: 125px;
      text-align: left;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 820px) {
    & {
      right: 583px;
      bottom: 135px;
    }
  }

  @media only screen and (min-width: 821px) and (max-width: 1180px) {
    & {
      right: 583px;
      bottom: 135px;
    }
  }
`;
export const Lens = styled.div `
  position: absolute;
  bottom: 55px;
  right: 155px;
  width: 450px;
  height: 450px;
  background-image: url(${lens});
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 11;

  @media only screen and (max-width: 768px) {
    & {
      width: 200px;
      height: 200px;
      bottom: 45px;
      right: 45px;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 820px) {
    & {
      width: 300px;
      height: 300px;
      bottom: 45px;
      right: 45px;
    }
  }

  @media only screen and (min-width: 821px) and (max-width: 1180px) {
    & {
      width: 300px;
      height: 300px;
      bottom: 45px;
      right: 45px;
    }
  }
`;
export const ModeArrow = styled.div `
  position: absolute;
  top: 105px;
  right: 105px;
  width: 90px;
  height: 78px;
  background-image: url(${modeArrow});
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 12;

  @media only screen and (max-width: 768px) {
    & {
      top: 115px;
      right: calc(50vw - 120px);
      width: 28px;
      height: 88px;
      background-image: url(${modeArrowMobile});
    }
  }
`;
export const ModeLabel = styled.div `
  position: absolute;
  top: 160px;
  right: 180px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #5732d3;
  max-width: 260px;
  text-align: left;
  z-index: 12;

  @media only screen and (max-width: 768px) {
    & {
      top: 210px;
      right: calc(50vw - 165px);
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      width: 190px;
      max-width: 190px;
      text-align: left;
    }
  }
`;
export const GetStartedButton = styled(Button) `
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 180px);
  width: 360px;
  height: 120px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.24);
  border-radius: 54px;
  font-weight: 600;
  font-size: 40px;
  line-height: 135%;
  z-index: 13;

  @media only screen and (max-width: 768px) {
    & {
      top: calc(50% - 25px);
      left: calc(50% - 97px);
      width: 194px;
      height: 50px;
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 820px) {
    & {
      top: calc(50% - 24px);
      left: calc(50% - 136px);
      width: 272px;
      height: 70px;
      font-size: 24px;
    }
  }

  @media only screen and (min-width: 821px) and (max-width: 1024px) {
    & {
      top: calc(50% - 24px);
      left: calc(50% - 136px);
      width: 272px;
      height: 70px;
      font-size: 24px;
    }
  }

  @media only screen and (min-width: 1025px) and (max-width: 1180px) {
    & {
      top: calc(50% - 50px);
      left: calc(50% - 158px);
      width: 316px;
      height: 100px;
      font-size: 32px;
    }
  }
`;
