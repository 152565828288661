import styled from "styled-components";
import { flexbox, space, layout } from "styled-system";
export const SwitchContainer = styled.div `
  display: flex;
  background-color: #f6f5f8;
  border-radius: 0.75rem;
`;
export const SwitchItemContainer = styled.span `
  display: inline-flex;
  background-color: ${({ active }) => (active ? "#5732D3" : "transparent")};
  padding: 0.75rem;
  color: ${({ active }) => (active ? "#ffffff" : "#7b7997")};
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: -0.011rem;
  border-radius: 0.75rem;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
  ${flexbox}
  ${space}
  ${layout}

  @media only screen and (max-width: 768px) {
    & {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1rem;
      letter-spacing: -0.00825rem;
    }
  }
`;
