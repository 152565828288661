import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { InputContainer, InputField, InputPrefix, InputSuffix } from "./styled";
const InputWrapper = ({ prefix, suffix, inputRenderer, className, hideArrows, style, width, error, value }) => (_jsxs(InputContainer, { className: className, hideArrows: hideArrows, style: style, width: width, error: error, value: value, children: [prefix && _jsx(InputPrefix, { children: prefix }), inputRenderer, suffix && _jsx(InputSuffix, { children: suffix })] }));
const InputRenderer = props => {
    const inputRef = useRef(null);
    const { name, value, defaultValue, placeholder, onEnter, onChange, onKeyDown, type, disabled, autofocus, min, max, error, } = props;
    const [ownValue, setOwnValue] = useState(value || defaultValue || "");
    useEffect(() => {
        if (inputRef.current && autofocus) {
            inputRef.current.focus();
        }
    }, [inputRef, autofocus]);
    return (_jsx(InputField, { "data-qa": "input", ref: inputRef, type: type, name: name, value: value ?? ownValue, placeholder: placeholder, min: min, max: max, error: error, onKeyDown: (e) => {
            if (e.key === "Enter" && typeof onEnter === "function") {
                e.preventDefault();
                return onEnter();
            }
            onKeyDown && onKeyDown(e);
        }, onChange: (e) => {
            const currentValue = e.target.value;
            onChange &&
                onChange(type === "number"
                    ? currentValue && (min ? +currentValue >= min : true)
                        ? +currentValue === 0
                            ? currentValue
                            : parseFloat(currentValue)
                        : undefined
                    : currentValue);
            setOwnValue(currentValue);
        }, disabled: disabled }));
};
const TextInput = props => (_jsx(InputWrapper, { inputRenderer: _jsx(InputRenderer, { type: "text", ...props }), ...props }));
const NumberInput = props => (_jsx(InputWrapper, { inputRenderer: _jsx(InputRenderer, { type: "number", ...props }), ...props }));
const PasswordInput = props => (_jsx(InputWrapper, { inputRenderer: _jsx(InputRenderer, { type: "password", ...props }), ...props }));
export { TextInput, NumberInput, PasswordInput };
