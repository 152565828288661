import { jsx as _jsx } from "react/jsx-runtime";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import WebFont from "webfontloader";
import { App } from "./components/App";
import "./assets/main.css";
WebFont.load({ google: { families: ["Inter:100,200,300,400,500,600,700"] } });
export const Root = () => {
    return (_jsx(BrowserRouter, { children: _jsx(App, {}) }));
};
const root = createRoot(document.getElementById("root"));
root.render(_jsx(Root, {}));
