import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ResultDialogContainer, ResultDialogFormItem, InvalidEmail } from "./styled";
import { Button, Checkbox, Icon, Loader, Select, Switch, TextInput } from "../../ui";
import { apiPath } from "../../api";
const INDUSTRY_OPTIONS = [
    { value: "Local and Central Government", label: "Local and Central Government" },
    { value: "Property Development", label: "Property Development" },
    { value: "Estate Agency", label: "Estate Agency" },
    { value: "Investment and Finance", label: "Investment and Finance" },
    { value: "Consultant", label: "Consultant" },
    { value: "Venture Capitalist", label: "Venture Capitalist" },
    { value: "Engineering and Architecture", label: "Engineering and Architecture" },
    { value: "Construction", label: "Construction" },
    { value: "Agriculture and Natural Resources", label: "Agriculture and Natural Resources" },
    { value: "Public Services", label: "Public Services" },
    { value: "Transportation", label: "Transportation" },
    { value: "Academia", label: "Academia" },
    { value: "Media", label: "Media" },
    { value: "Other", label: "Other" },
];
const OWN_ITEMS = [
    { value: 1, label: "Own this land plot" },
    { value: 0, label: "Not my land plot" },
];
const VALID_EMAIL_REGEX = 
// eslint-disable-next-line no-control-regex, max-len
/^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
export const ClickResultDialog = ({ address, coordinates, featureId, onReset }) => {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [industry, setIndustry] = useState(null);
    const [accepted, setAccepted] = useState(false);
    const [own, setOwn] = useState(OWN_ITEMS.find(item => item.value === 1));
    const [requestSent, setRequestSent] = useState(false);
    const [isError, setIsError] = useState(false);
    const [requestLoading, setRequestLoading] = useState(false);
    const emailError = useMemo(() => email && !email.match(VALID_EMAIL_REGEX), [email]);
    const onRequest = useCallback(async () => {
        setRequestLoading(true);
        fetch(
        // eslint-disable-next-line max-len
        `${apiPath}/email_feature/?name=${fullName}&address=${address}&email=${email}&feature=${featureId}&coordinates=${coordinates[0]},${coordinates[1]}&industry=${industry.label}&own=${own.value}&type=payment`).then(() => {
            setRequestLoading(false);
            setRequestSent(true);
        });
    }, [address, coordinates, email, featureId, fullName, industry, own]);
    const onSubmit = useCallback(async () => {
        if (!fullName || !email || emailError || !industry || !accepted) {
            return setIsError(true);
        }
        if (requestSent) {
            onReset();
        }
        else {
            await onRequest();
        }
    }, [fullName, email, emailError, industry, accepted, requestSent, onReset, onRequest]);
    useEffect(() => {
        setRequestSent(false);
    }, [featureId]);
    return (_jsxs(ResultDialogContainer, { children: [_jsx("h2", { children: "Detailed Site Report" }), _jsx("p", { children: "Fill the form to receive your report" }), _jsx(ResultDialogFormItem, { children: _jsx(TextInput, { error: isError && !fullName, placeholder: "Full Name", value: fullName, onChange: e => {
                        setIsError(false);
                        setFullName(e);
                    } }) }), _jsxs(ResultDialogFormItem, { children: [_jsx(TextInput, { error: isError && (!email || emailError), placeholder: "example@gmail.com", value: email, onChange: e => {
                            setIsError(false);
                            setEmail(e);
                        } }), isError && emailError && _jsx(InvalidEmail, { children: "Invalid email format" })] }), _jsx(ResultDialogFormItem, { children: _jsx(Select, { error: isError && !industry, options: INDUSTRY_OPTIONS, value: industry, onChange: e => {
                        setIsError(false);
                        setIndustry(e);
                    }, placeholder: "Industry/Sector" }) }), _jsx(ResultDialogFormItem, { children: _jsx(Switch, { activeItem: own, items: OWN_ITEMS, onChange: setOwn }) }), _jsx(ResultDialogFormItem, { children: _jsx(Checkbox, { error: isError && !accepted, label: _jsxs(_Fragment, { children: ["I agree and accept", " ", _jsx("a", { target: "_blank", style: { color: isError && !accepted ? "#ed627f" : "#5732d3", textDecoration: "none" }, onClick: e => e.stopPropagation(), href: "https://onecity.dev/terms", rel: "noreferrer", children: "Terms and Conditions" })] }), checked: accepted, onChange: () => {
                        setIsError(false);
                        setAccepted(!accepted);
                    } }) }), requestSent ? (_jsxs(Button, { width: "100%", style: { backgroundColor: "#03c75d", borderColor: "#03c75d", color: "white", cursor: "default" }, children: [_jsx(Icon, { icon: "done", mr: "7px", size: 18 }), "Request Sent"] })) : (_jsxs(Button, { onClick: onSubmit, disabled: requestLoading, width: "100%", children: [requestLoading && _jsx(Loader, { color: "white", mr: "8px" }), requestLoading ? "Sending request..." : _jsx(_Fragment, { children: "Request Report for R\u200960" })] }))] }));
};
