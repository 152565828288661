import styled, { css } from "styled-components";
const hideArrowsMixin = css `
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const InputContainer = styled.div `
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: ${({ error, value }) => (error ? "#ffcae2" : value ? "#dde0ff" : "#f6f5f8")};
  border-radius: 12px;
  width: ${({ width }) => width || "100%"};
  ${({ hideArrows }) => hideArrows && hideArrowsMixin}
`;
export const InputField = styled.input `
  width: 100%;
  border: none;
  outline: none;
  color: ${({ error }) => (error ? "#ed627f" : "#5732d3")};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 12px;
  background-color: transparent;

  @media only screen and (max-width: 768px) {
    & {
      font-size: 12px;
    }
  }

  &::placeholder {
    color: ${({ error }) => (error ? "#ed627f" : "#7b7997")};
  }
`;
export const InputPrefix = styled.div `
  padding: 0 0 0 16px;
`;
export const InputSuffix = styled.div `
  padding: 0 16px 0 0;
`;
