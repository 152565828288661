import styled from "styled-components";
import { Button } from "../../ui";
import { Icon } from "../../ui/Icon/styled";
export const LayersControlWrapper = styled.div `
  position: absolute;
  bottom: 22px;
  right: 13px;
  z-index: 3;
  text-align: right;

  @media only screen and (max-width: 768px) {
    & {
      bottom: 8px;
      right: 8px;
    }
  }
`;
export const LayersButton = styled(Button) `
  position: relative;
  right: 0;
  background-color: ${({ active }) => active && "#dde0ff"};
  border-color: ${({ active }) => active && "#dde0ff"};
  color: ${({ active }) => active && "#5732d3"};

  ${Icon} {
    margin-right: 7px;

    @media only screen and (max-width: 768px) {
      & {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    & {
      width: 44px;
      height: 44px;
    }
  }
`;
export const LayersButtonLabel = styled.span `
  @media only screen and (max-width: 768px) {
    & {
      display: none;
    }
  }
`;
export const LayersPopover = styled.div `
  position: relative;
  bottom: 10px;
  width: 180px;
  background: #ffffff;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.24);
  border-radius: 12px;
`;
export const LayersList = styled.ul `
  padding: 4px;
  margin: 0;
  list-style-type: none;
  text-align: initial;
`;
export const LayersListOption = styled.li `
  padding: 12px;
  background-color: ${({ active }) => (active ? "#dde0ff" : "#f6f5f8")};
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.011em;
  color: ${({ active }) => (active ? "#5732d3" : "#7b7997")};
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    & {
      font-size: 12px;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: #dde0ff;
      color: #5732d3;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 2px;
  }
`;
