import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GeolocateControl, Layer, Marker, NavigationControl, Source, } from "react-map-gl";
import { AppWrapper, LENS_BORDER_WIDTH } from "./styled";
import { Map } from "../Map";
import { GeocoderControl } from "../GeocoderControl";
import { ModeControl } from "../ModeControl";
import { LayersControl } from "../LayersControl";
import { LensResultDialog } from "../LensResultDialog";
import { ClickResultDialog } from "../ClickResultDialog";
import { CustomMarker } from "../CustomMarker";
import { ClickOnTheMap } from "../ClickOnTheMap";
import { AddressConfirm } from "../AddressConfirm";
import { ZeroScreen } from "../ZeroScreen";
import { metersPerPixel } from "../../utils/metersPerPixel";
import { debounce } from "../../utils/debounce";
import { useAppHeight } from "../../hooks/useAppHeight";
import { isTouch } from "../../utils/isTouch";
import { SelectionModes } from "../ModeControl/types";
const DEFAULT_LNG = 28.086095;
const DEFAULT_LAT = -26.119949;
const DEFAULT_ZOOM = 15;
const SEARCH_RADIUS = 600;
// eslint-disable-next-line max-lines-per-function
export const App = () => {
    const [searchParams] = useSearchParams();
    const splash = searchParams.get("splash");
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [mainMapViewport, setMainMapViewport] = useState({});
    const [lensViewport, setLensViewport] = useState();
    const [selectedPoint, setSelectedPoint] = useState();
    const [selectedFeature, setSelectedFeature] = useState();
    const [selectedFeatureCoordinates, setSelectedFeatureCoordinates] = useState();
    const [selectedPointConfirm, setSelectedPointConfirm] = useState();
    const [selectionMode, setSelectionMode] = useState(SelectionModes.Lens);
    const [lensRadius, setLensRadius] = useState(Math.round(SEARCH_RADIUS / metersPerPixel(DEFAULT_ZOOM, DEFAULT_LAT)));
    const [mousePosition, setMousePosition] = useState();
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [zoomingMap, setZoomingMap] = useState(false);
    const [draggingMap, setDraggingMap] = useState(false);
    const mainMap = useRef();
    const lensMap = useRef();
    const [mapStyleType, setMapStyleType] = useState();
    const [address, setAddress] = useState("");
    const [addressConfirm, setAddressConfirm] = useState("");
    const hoveredPolygonId = useRef(null);
    const activePolygonId = useRef(null);
    const mapStyle = useMemo(() => (mapStyleType === 1 ? "mapbox://styles/mapbox/satellite-streets-v12" : "mapbox://styles/mapbox/light-v11"), [mapStyleType]);
    const lensLayer = useMemo(() => {
        return {
            id: "lens-layer",
            type: "fill",
            "source-layer": process.env.LENS_SOURCE_LAYER,
            paint: {
                "fill-color": selectedPoint ? "#7d66db" : "#9f9f9f",
                "fill-opacity": 0.8,
            },
        };
    }, [selectedPoint]);
    const clickLayer = useMemo(() => {
        return {
            id: "click-layer",
            type: "fill",
            source: "click-source",
            "source-layer": process.env.CLICK_SOURCE_LAYER,
            paint: {
                "fill-color": [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    "#dde0ff",
                    ["boolean", ["feature-state", "clicked"], false],
                    "#5732d3",
                    "#9f9f9f",
                ],
                "fill-opacity": 0.8,
            },
        };
    }, []);
    const resetMapState = useCallback((ignoreAddressReset) => {
        setMousePosition(undefined);
        setSelectedPoint(undefined);
        setSelectedFeature(undefined);
        setSelectedFeatureCoordinates(undefined);
        setSelectedPointConfirm(undefined);
        !ignoreAddressReset && setAddress("");
        setAddressConfirm("");
    }, []);
    const fetchReverseGeocoder = useCallback((lon, lat, callback) => {
        fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${process.env.ACCESS_TOKEN}`)
            .then(response => response.json())
            .then(callback);
    }, []);
    const onZoom = useCallback((e) => {
        setZoom(e.viewState.zoom);
        setLensRadius(Math.round(SEARCH_RADIUS / metersPerPixel(e.viewState.zoom, e.viewState.latitude)));
        resetMapState(true);
    }, [resetMapState]);
    const onMouseMove = useCallback((e, shouldCancel) => {
        if (selectionMode === SelectionModes.Lens) {
            if (isTouch()) {
                if (!mousePosition) {
                    setMousePosition([window.innerWidth / 2, window.innerHeight / 2]);
                    setMainMapViewport({
                        longitude: e.lngLat.lng,
                        latitude: e.lngLat.lat,
                    });
                    setLensViewport({
                        longitude: e.lngLat.lng,
                        latitude: e.lngLat.lat,
                    });
                    setSelectedPointConfirm([e.lngLat.lng, e.lngLat.lat]);
                    fetchReverseGeocoder(e.lngLat.lng, e.lngLat.lat, response => setAddressConfirm(response.features[0]?.place_name));
                }
                else {
                    resetMapState();
                }
                return;
            }
            if (shouldCancel) {
                return;
            }
            setMousePosition([e.originalEvent.pageX, e.originalEvent.pageY]);
            setLensViewport({
                longitude: e.lngLat.lng,
                latitude: e.lngLat.lat,
            });
        }
        if (selectionMode === SelectionModes.Click) {
            if (isTouch() || selectedFeature !== undefined || !mainMap.current) {
                return;
            }
            const features = mainMap.current.queryRenderedFeatures(e.point, { layers: ["click-layer"] });
            if (hoveredPolygonId.current !== null) {
                mainMap.current.setFeatureState({ source: "click-source", sourceLayer: process.env.CLICK_SOURCE_LAYER, id: hoveredPolygonId.current }, { hover: false });
            }
            if (features.length > 0) {
                hoveredPolygonId.current = features[0].id;
                mainMap.current.setFeatureState({ source: "click-source", sourceLayer: process.env.CLICK_SOURCE_LAYER, id: hoveredPolygonId.current }, { hover: true });
            }
        }
    }, [fetchReverseGeocoder, mousePosition, resetMapState, selectionMode, selectedFeature]);
    const onMainMapClick = useCallback((e) => {
        if (selectionMode === SelectionModes.Lens) {
            if (selectedPoint !== undefined) {
                resetMapState();
            }
            return;
        }
        if (selectionMode === SelectionModes.Click) {
            const features = mainMap.current.queryRenderedFeatures(e.point, { layers: ["click-layer"] });
            if (activePolygonId.current !== null) {
                mainMap.current.setFeatureState({ source: "click-source", sourceLayer: process.env.CLICK_SOURCE_LAYER, id: activePolygonId.current }, { clicked: false });
            }
            if (features.length > 0) {
                activePolygonId.current = features[0].id;
                mainMap.current.setFeatureState({ source: "click-source", sourceLayer: process.env.CLICK_SOURCE_LAYER, id: activePolygonId.current }, { clicked: true });
                setSelectedFeature(features[0].properties.OBJECTID);
                setSelectedFeatureCoordinates([e.lngLat.lng, e.lngLat.lat]);
                fetchReverseGeocoder(e.lngLat.lng, e.lngLat.lat, response => setAddress(response.features[0]?.place_name));
            }
            else {
                setSelectedFeature(undefined);
                setSelectedFeatureCoordinates(undefined);
            }
        }
    }, [fetchReverseGeocoder, resetMapState, selectedPoint, selectionMode]);
    const onLensMapClick = useCallback((e) => {
        if (!selectedPoint && !isTouch()) {
            setSelectedPoint([e.lngLat.lng, e.lngLat.lat]);
            fetchReverseGeocoder(e.lngLat.lng, e.lngLat.lat, response => setAddress(response.features[0]?.place_name));
        }
        else {
            resetMapState();
        }
    }, [selectedPoint, fetchReverseGeocoder, resetMapState]);
    const onGeocoderResult = useCallback((feature) => {
        if (!feature) {
            return;
        }
        const { center, place_name } = feature;
        if (!center || !lensMap.current) {
            return;
        }
        const updatedViewport = {
            longitude: center[0],
            latitude: center[1],
        };
        setMainMapViewport(updatedViewport);
        setMousePosition(undefined);
        setAddress(place_name);
    }, []);
    useEffect(() => {
        if (selectedFeature === undefined) {
            setAddress("");
            if (activePolygonId.current !== null) {
                mainMap.current.setFeatureState({ source: "click-source", sourceLayer: process.env.CLICK_SOURCE_LAYER, id: activePolygonId.current }, { clicked: false });
            }
            activePolygonId.current = null;
        }
        else if (hoveredPolygonId.current !== null) {
            mainMap.current.setFeatureState({ source: "click-source", sourceLayer: process.env.CLICK_SOURCE_LAYER, id: hoveredPolygonId.current }, { hover: false });
            hoveredPolygonId.current = null;
        }
    }, [selectedFeature]);
    useEffect(() => {
        resetMapState();
    }, [selectionMode]); // eslint-disable-line
    useAppHeight();
    return (_jsxs(AppWrapper, { selected: selectedPoint !== undefined, selectionMode: selectionMode, children: [isInitialRender && splash !== "false" && _jsx(ZeroScreen, { setIsInitialRender: setIsInitialRender }), _jsxs(Map, { id: "oneCityMainMap", mapStyle: mapStyle, ...mainMapViewport, initialViewState: {
                    longitude: DEFAULT_LNG,
                    latitude: DEFAULT_LAT,
                    zoom: DEFAULT_ZOOM,
                }, touchPitch: false, minZoom: 13, maxZoom: 15, zoom: zoom, scrollZoom: selectedPoint === undefined, onZoomStart: () => setZoomingMap(true), onZoomEnd: () => setZoomingMap(false), onTouchStart: () => setZoomingMap(true), onTouchEnd: () => setZoomingMap(false), onZoom: onZoom, onMove: (e) => setMainMapViewport(e.viewState), onClick: onMainMapClick, onMouseMove: debounce((e) => {
                    onMouseMove(e, selectedPoint !== undefined || draggingMap);
                }, selectionMode === SelectionModes.Click ? 0 : 150), onMouseLeave: () => setMousePosition(undefined), onDragStart: () => (selectedPoint !== undefined || !!addressConfirm) && resetMapState(), onLoad: e => (mainMap.current = e.target), children: [!zoomingMap && selectionMode === SelectionModes.Lens && (_jsxs(Map, { id: "oneCityLens", mapStyle: mapStyle, ...lensViewport, initialViewState: {
                            zoom: DEFAULT_ZOOM,
                        }, touchPitch: false, style: {
                            width: `${(lensRadius - LENS_BORDER_WIDTH) * 2}px`,
                            height: `${(lensRadius - LENS_BORDER_WIDTH) * 2}px`,
                            borderRadius: `${lensRadius * 2}px`,
                            left: `${(mousePosition?.[0] ?? 0) - lensRadius - LENS_BORDER_WIDTH}px`,
                            top: `${(mousePosition?.[1] ?? 0) - lensRadius - LENS_BORDER_WIDTH}px`,
                            visibility: mousePosition !== undefined || selectedPoint !== undefined ? "visible" : "hidden",
                            opacity: draggingMap ? 0 : 1,
                            borderColor: selectedPoint === undefined ? "#ffffff" : "#5732D3",
                        }, minZoom: 13, maxZoom: 15, zoom: zoom, scrollZoom: selectedPoint === undefined, onZoomStart: () => setZoomingMap(true), onZoomEnd: () => setZoomingMap(false), onTouchStart: () => setZoomingMap(true), onTouchEnd: () => setZoomingMap(false), onZoom: onZoom, onClick: onLensMapClick, onMouseMove: e => onMouseMove(e, selectedPoint !== undefined || mousePosition === undefined), onDragStart: () => {
                            setDraggingMap(true);
                            setAddressConfirm("");
                        }, onDrag: (e) => setMainMapViewport(e.viewState), onDragEnd: () => {
                            setDraggingMap(false);
                            setMousePosition(undefined);
                        }, onLoad: e => (lensMap.current = e.target), children: [_jsx(Source, { type: "vector", url: process.env.LENS_SRC_URL, children: _jsx(Layer, { ...lensLayer }) }), selectedPoint !== undefined && (_jsx(Marker, { longitude: selectedPoint[0], latitude: selectedPoint[1], children: _jsx(CustomMarker, {}) })), selectedPointConfirm !== undefined && (_jsx(Marker, { longitude: selectedPointConfirm[0], latitude: selectedPointConfirm[1], children: _jsx(CustomMarker, {}) }))] })), _jsx(GeocoderControl, { value: address, onChange: value => {
                            resetMapState();
                            setAddress(value);
                        }, onPick: onGeocoderResult }), _jsx(ModeControl, { currentMode: selectionMode, onChangeMode: setSelectionMode }), _jsx(NavigationControl, { showCompass: false }), _jsx(GeolocateControl, {}), _jsx(LayersControl, { onSelect: setMapStyleType }), selectedPoint && _jsx(LensResultDialog, { address: address, coordinates: selectedPoint, onReset: resetMapState }), selectedFeature && (_jsx(ClickResultDialog, { address: address, coordinates: selectedFeatureCoordinates, featureId: selectedFeature, onReset: resetMapState })), !isInitialRender && !addressConfirm && !selectedPoint && _jsx(ClickOnTheMap, {}), addressConfirm && !zoomingMap && (_jsx(AddressConfirm, { address: addressConfirm, onConfirm: () => {
                            setAddress(addressConfirm);
                            setSelectedPoint(selectedPointConfirm);
                            setAddressConfirm("");
                            setSelectedPointConfirm(undefined);
                        } })), selectionMode === SelectionModes.Click && (_jsx(Source, { id: "click-source", type: "vector", url: process.env.CLICK_SRC_URL, promoteId: "OBJECTID", children: _jsx(Layer, { ...clickLayer }) }))] })] }));
};
