import styled from "styled-components";
import { space } from "styled-system";
const DEFAULT_LOADER_SIZE = 24;
const getTransform = (size) => `${(32 / 70) * (size || DEFAULT_LOADER_SIZE)}px`;
const getLeftOffset = (size) => `${(30 / 70) * (size || DEFAULT_LOADER_SIZE)}px`;
const getDashWidth = (size) => `${(5 / 70) * (size || DEFAULT_LOADER_SIZE)}px`;
const getDashHeight = (size) => `${(18 / 70) * (size || DEFAULT_LOADER_SIZE)}px`;
export const LoaderContainer = styled.div `
  display: inline-block;
  position: relative;
  width: ${({ size }) => `${size || DEFAULT_LOADER_SIZE}px`};
  height: ${({ size }) => `${size || DEFAULT_LOADER_SIZE}px`};
  ${space}

  & > div {
    -webkit-transform-origin: ${({ size }) => `${getTransform(size)} ${getTransform(size)}`};
    -ms-transform-origin: ${({ size }) => `${getTransform(size)} ${getTransform(size)}`};
    transform-origin: ${({ size }) => `${getTransform(size)} ${getTransform(size)}`};
    -webkit-animation: 1.2s opaque ease-in-out infinite both;
    animation: 1.2s opaque ease-in-out infinite both;
  }

  & > div::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: ${({ size }) => getLeftOffset(size)};
    width: ${({ size }) => getDashWidth(size)};
    height: ${({ size }) => getDashHeight(size)};
    background-color: ${({ color }) => color ?? "#5732d3"};
  }

  & > div:nth-child(1) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  & > div:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  & > div:nth-child(3) {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  & > div:nth-child(4) {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  & > div:nth-child(5) {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

  & > div:nth-child(6) {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }

  & > div:nth-child(7) {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  & > div:nth-child(8) {
    -webkit-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    transform: rotate(315deg);
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }

  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes opaque {
    0% {
      opacity: 0.1;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0.1;
    }
    100% {
      opacity: 0.1;
    }
  }

  @keyframes opaque {
    0% {
      opacity: 0.1;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0.1;
    }
    100% {
      opacity: 0.1;
    }
  }
`;
export const WrapLoader = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
