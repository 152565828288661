import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { CheckboxInput, CheckboxCheckMark, CheckboxWrapper, CheckboxLabel } from "./styled";
export const Checkbox = ({ children, checked, label, disabled, onChange, name, className, error, ...props }) => {
    const [ownValue, setOwnValue] = useState(checked || false);
    return (_jsxs(CheckboxWrapper, { className: className, disabled: disabled, children: [_jsx(CheckboxInput, { name: name, checked: checked !== undefined ? checked : ownValue || false, onChange: e => {
                    setOwnValue(e.target.checked);
                    return !onChange || onChange(e);
                }, disabled: disabled, ...props }), _jsx(CheckboxCheckMark, { disabled: disabled, error: error }), _jsx(CheckboxLabel, { error: error, children: label })] }));
};
