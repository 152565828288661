import { useCallback, useEffect, useRef } from "react";
export const useClickOutside = (callback) => {
    const ref = useRef(null);
    const handleClick = useCallback(e => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    }, [callback]);
    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [handleClick]);
    return ref;
};
