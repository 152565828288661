import styled from "styled-components";
export const ResultDialogContainer = styled.div `
  position: absolute;
  top: 78px;
  left: 30px;
  width: 400px;
  background-color: #ffffff;
  padding: 32px 20px 20px;
  box-sizing: border-box;
  z-index: 5;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);

  @media only screen and (max-width: 768px) {
    & {
      top: 56px;
      left: 8px;
      width: calc(100% - 16px);
      box-sizing: border-box;
      padding-top: 20px;
    }
  }

  h2 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.011em;

    @media only screen and (max-width: 768px) {
      & {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.011em;
    margin: 8px 0 0;

    @media only screen and (max-width: 768px) {
      & {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
`;
export const LoaderContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.011em;
  color: #7b7997;

  @media only screen and (max-width: 768px) {
    & {
      font-size: 12px;
      line-height: 12px;
    }
  }
`;
export const ResultDialogFormItem = styled.div `
  margin: 12px 0;

  &:first-of-type {
    margin-top: 20px;
  }

  &:last-of-type {
    margin-bottom: 34px;

    @media only screen and (max-width: 768px) {
      & {
        margin-bottom: 20px;
      }
    }
  }
`;
export const InvalidEmail = styled.div `
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.011em;
  color: #ed627f;
  margin-top: 6px;
`;
export const NoSites = styled.div `
  background: #7b7997;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.011em;
  color: #e1e1e1;
  padding: 12px;

  h3 {
    margin: 0 0 4px 0;
    padding: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }
`;
