import styled from "styled-components";
import { space } from "styled-system";
import { Icon } from "../Icon/styled";
export const CustomButton = styled.button `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? (typeof width === "string" ? width : `${width}px`) : "auto")};
  height: ${({ height }) => (height ? (typeof height === "string" ? height : `${height}px`) : "52px")};
  padding: ${({ padding }) => padding || "0 16px"};
  background-color: ${({ secondary }) => (!secondary ? "#5732d3" : "white")};
  border: 1px solid #5732d3;
  border-radius: 12px;
  color: ${({ disabled, secondary }) => (disabled ? "rgba(255, 255, 255, 0.35)" : !secondary ? "white" : "#1976d2")};
  cursor: ${({ disabled }) => !disabled && "pointer"};
  transition: 0.1s ease-in-out;
  font-family: Inter, serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.011em;
  box-sizing: border-box;
  pointer-events: ${({ loading, disabled }) => (loading || disabled ? "none" : "initial")};
  user-select: none;
  ${space}

  @media (hover: hover) {
    &:hover {
      background-color: ${({ secondary }) => (!secondary ? "#dde0ff" : "white")};
      border-color: ${({ secondary }) => (!secondary ? "#dde0ff" : "white")};
      color: ${({ disabled, secondary }) => disabled ? "rgba(255, 255, 255, 0.35)" : !secondary ? "#5732d3" : "#1976d2"};
    }
  }

  @media (hover: none) {
    &:active {
      background-color: ${({ secondary }) => (!secondary ? "#dde0ff" : "white")};
      border-color: ${({ secondary }) => (!secondary ? "#dde0ff" : "white")};
      color: ${({ disabled, secondary }) => disabled ? "rgba(255, 255, 255, 0.35)" : !secondary ? "#5732d3" : "#1976d2"};
    }
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  ${Icon} {
    transition: none;
  }
`;
