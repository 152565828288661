import styled from "styled-components";
export const ClickOnTheMapContainer = styled.div `
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 144px;
  height: 44px;
  left: calc(50% - 144px / 2);
  bottom: 8px;
  background: #000000;
  border-radius: 12px;

  @media (hover: none) and (pointer: coarse) {
    & {
      display: flex;
    }
  }

  @media only screen and (min-width: 769px) {
    & {
      width: 200px;
      bottom: 22px;
      right: 13px;
      height: 52px;
    }
  }
`;
export const ClickOnTheMapIcon = styled.img `
  height: 20px;
  margin-right: 4px;

  @media only screen and (min-width: 769px) {
    & {
      height: 30px;
    }
  }
`;
export const ClickOnTheMapLabel = styled.div `
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.011em;
  color: #ffffff;

  @media only screen and (min-width: 769px) {
    & {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
