import styled, { css, keyframes } from "styled-components";
import { space } from "styled-system";
const progressMove = keyframes `
  0% { left: 0; }
  33.3% { left: 0; }
  100% { left: 100%; }
`;
const progressGrow = keyframes `
  0% { width: 0; }
  66.6% { width: 60%; }
  100% { width: 60%; }
`;
const transitionMixin = css `
  animation: ${progressMove} 1200ms ease-in-out infinite, ${progressGrow} 1200ms ease-in-out infinite;
`;
const smoothTransition = css `
  transition: width 300ms linear;
`;
export const LinearProgress = styled.div `
  position: relative;
  height: 4px;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  background-color: transparent;
  ${space}

  &:after {
    content: "";
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 4px;
    width: ${({ done }) => (done !== void 0 ? `${done}%` : 0)};
    background-color: #5732d3;
    ${({ done }) => (done === void 0 ? transitionMixin : smoothTransition)};
  }
`;
