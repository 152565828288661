import styled from "styled-components";
import { CustomButton } from "../../ui/Button/styled";
export const AddressConfirmContainer = styled.div `
  visibility: ${({ width }) => (width === 0 ? "hidden" : "visible")};
  position: absolute;
  top: calc(var(--app-height) / 2 - 72px);
  left: calc(100vw / 2 - ${({ width }) => width / 2}px);
  display: flex;
  align-items: center;
  height: 36px;
  width: ${({ width }) => width};
  max-width: calc(100vw - 132px);
  background: #dde0ff;
  border: 2px solid #5732d3;
  border-radius: 12px;
  padding: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.011em;
  color: #5732d3;
  z-index: 14;

  ${CustomButton} {
    border-radius: 6px;
    padding: 0 4px;
  }
`;
export const AddressConfirmAddress = styled.div `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
`;
