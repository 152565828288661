import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { LayersButton, LayersControlWrapper, LayersPopover, LayersList, LayersListOption, LayersButtonLabel, } from "./styled";
import { Icon } from "../../ui";
import { useClickOutside } from "../../hooks/useClickOutside";
const DEFAULT_OPTION = 2;
export const LayersControl = ({ onSelect }) => {
    const [expanded, setExpanded] = useState(false);
    const ref = useClickOutside(() => setExpanded(false));
    const [selectedOption, setSelectedOption] = useState(DEFAULT_OPTION);
    const onItemSelect = useCallback((value) => {
        setExpanded(false);
        setSelectedOption(value);
        onSelect && onSelect(value);
    }, [onSelect]);
    useEffect(() => {
        onSelect && onSelect(DEFAULT_OPTION);
    }, []); // eslint-disable-line
    return (_jsxs(LayersControlWrapper, { ref: ref, children: [expanded && (_jsx(LayersPopover, { children: _jsxs(LayersList, { children: [_jsx(LayersListOption, { onClick: () => onItemSelect(1), active: selectedOption === 1, children: "Satellite" }), _jsx(LayersListOption, { onClick: () => onItemSelect(2), active: selectedOption === 2, children: "Schematic" })] }) })), _jsxs(LayersButton, { active: expanded, onClick: () => setExpanded(!expanded), children: [_jsx(Icon, { icon: "layers", size: 18 }), _jsx(LayersButtonLabel, { children: "Layers" })] })] }));
};
