import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { SwitchContainer, SwitchItemContainer } from "./styled";
export const Switch = ({ items = [], activeItem, onChange }) => {
    const [currentItem, setCurrentItem] = useState(items.find(item => item.value === activeItem.value));
    if (items.length === 0) {
        return null;
    }
    return (_jsx(SwitchContainer, { children: items.map((item, itemIndex) => {
            return (_jsx(SwitchItemContainer, { width: `calc(100% / ${items.length})`, active: currentItem.value === item.value, onClick: () => {
                    if (currentItem.value !== item.value) {
                        setCurrentItem(item);
                        onChange && onChange(item);
                    }
                }, children: item.label }, `switch-item-${itemIndex}`));
        }) }));
};
