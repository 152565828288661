import styled from "styled-components";
export const CheckboxInput = styled.input.attrs(({ checked }) => ({
    type: "checkbox",
    hidden: true,
    checked: !!checked,
})) `
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
`;
export const CheckboxWrapper = styled.label `
  display: flex;
  align-items: center;
  text-align: left;
  font-family: Inter, serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.011em;
  color: #7b7997;

  @media only screen and (max-width: 768px) {
    & {
      font-size: 12px;
    }
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }

  &[disabled] {
    pointer-events: none;
  }
`;
export const CheckboxCheckMark = styled.span `
  position: relative;
  display: flex;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  margin-right: 12px;
  border: 2px solid ${({ error }) => (error ? "#ed627f" : "#5732d3")};
  border-radius: 8px;
  box-sizing: border-box;
  background: #ffffff;

  ${CheckboxInput}:checked + & {
    border: 2px solid #5732d3;
    background: #ffffff;

    &::after {
      box-sizing: border-box;
      position: absolute;
      top: 1px;
      left: 1px;
      content: "";
      display: block;
      background-color: #5732d3;
      width: 18px;
      height: 18px;
      border: 2px solid white;
      border-radius: 6px;
    }

    &[disabled] {
      background: #dde0ff;
      border: 1px solid #dde0ff;

      &::after {
        border-bottom: 3px solid #ffffff;
        border-left: 3px solid #ffffff;
      }
    }
  }

  &[disabled] {
    background: #ffffff;
    border-color: #9f9f9f;
  }
`;
export const CheckboxLabel = styled.div `
  user-select: none;
  color: ${({ error }) => error && "#ed627f"};
`;
