export const debounce = (callback, delay) => {
    let timeId;
    return (...args) => {
        if (timeId) {
            clearTimeout(timeId);
        }
        timeId = window.setTimeout(() => {
            callback(...args);
        }, delay);
    };
};
